import React, { useMemo } from 'react'
import { useBrandingData, useShipmentData, useTrackContextData } from './index'
import { useNotificationSetting } from './NotificationSettingProvider'

type FeatureEnables = {
  showPromoImage: boolean
  showBrandedTrackingLink: boolean
  showSocialMedia: boolean
  showMenuLinks: boolean
  showEmail: boolean
  showPhone: boolean
  showReturnPolicy: boolean
  showShipmentDetails: boolean
  showItemImages: boolean
  showShipmentPrices: boolean
  showItemOptions: boolean
  showSms: boolean
  showGiftLink: boolean
  usePromoImageLinks: boolean
  useStoreUrl: boolean
}

const FeatureEnablesContext = React.createContext({})
export const FeatureEnablesProvider = FeatureEnablesContext.Provider
export function useFeatureEnables(): FeatureEnables {
  const shipment = useShipmentData()
  const trackContext = useTrackContextData()
  const branding = useBrandingData()
  const notifSettings = useNotificationSetting()

  const featureEnables: FeatureEnables = useMemo(() => {
    const { toggles, phone, email, return_policy, social_media, menu_links } = branding
    const ebayOrderSources = trackContext.ebay_order_source_codes || []
    const isBrandingSuppressed = ebayOrderSources.includes(shipment.order_source_code || '')
    const isSmsUiSuppressed = true;

    return {
      showSocialMedia:
        !isBrandingSuppressed && toggles.show_social_media === true && social_media.filter(({ url }) => url).length > 0,
      showMenuLinks: !isBrandingSuppressed && toggles.show_menu_links === true && menu_links.length > 0,
      showEmail: !isBrandingSuppressed && toggles.show_email === true && !!email,
      showPhone: !isBrandingSuppressed && toggles.show_phone === true && !!phone,
      showReturnPolicy: toggles.show_return_policy === true && !!return_policy,
      showShipmentDetails: toggles.show_shipment_details === true,
      showItemImages: toggles.show_item_images === true,
      showShipmentPrices: toggles.show_shipment_prices === true,
      showItemOptions: toggles.show_item_options === true,
      showBrandedTrackingLink: toggles.show_branded_tracking_link === true,
      showPromoImage: toggles.show_promo_image === true,
      showSms:
        !isSmsUiSuppressed &&
        !!notifSettings.sms_enabled &&
        !trackContext.sms_prohibited_by_branding &&
        trackContext.sms_supported_country_codes.includes(shipment.destination?.country_code || ''),
      showGiftLink: !!toggles.show_gift_link,
      usePromoImageLinks: !isBrandingSuppressed,
      useStoreUrl: !isBrandingSuppressed && toggles.show_store_url === true,
    }
  }, [branding, shipment, trackContext, notifSettings])

  return featureEnables
}
