import React, { useContext } from 'react'

import { Branding as ApiBranding } from '../apiTypes/api'

export interface Branding {
  colors?: {
    primary?: string
    secondary?: string
    tertiary?: string
  }
  email?: string
  layout_key: LayoutKeys
  logo_type: 'text' | 'image'
  logo_url?: string
  menu_links: Array<Link>
  phone?: string
  powered_by?: {
    name?: string
    logo_url?: string
    url: string
  }
  return_policy?: string
  social_media: Array<SocialLink>
  store_name?: string
  store_url?: string
  theme_key: ThemeKeys
  toggles: Toggles
  promo_images?: {
    main?: {
      images?: PromoImage[]
    }
  }
}

interface PromoImage {
  image_url: string
  size_type: string
  link_url?: string
  title_text?: string
  crop: {
    x: number
    y: number
    width: number
    height: number
  }
}

export enum LayoutKeys {
  Layout01 = 'layout01',
  Layout02 = 'layout02',
  Layout03 = 'layout03',
}

export interface SocialLink {
  type: 'twitter' | 'instagram' | 'youtube' | 'pinterest' | 'facebook' | 'linkedin' | 'tiktok'
  url: string
}

export interface Link {
  url: string
  text: string
}

export enum ThemeKeys {
  Light = 'light',
  Dark = 'dark',
  Peach = 'peach',
  Unicorns = 'unicorns',
  Paparazzi = 'paparazzi',
  Custom = 'custom', // not currently included in API, used automatically when custom `colors` are defined in branding
}

interface Toggles {
  show_promo_image?: boolean
  show_branded_tracking_link?: boolean
  show_custom_colors?: boolean
  show_social_media?: boolean
  show_store_url?: boolean
  show_menu_links?: boolean
  show_return_policy?: boolean
  show_email?: boolean
  show_phone?: boolean
  show_shipment_details?: boolean
  show_item_images?: boolean
  show_item_options?: boolean
  show_shipment_prices?: boolean
  show_gift_link?: boolean
}

const DEFAULT_TOGGLES = {
  show_promo_image: true,
  show_branded_tracking_link: true,
  show_custom_colors: false,
  show_social_media: false,
  show_store_url: false,
  show_menu_links: false,
  show_return_policy: false,
  show_phone: false,
  show_email: false,
  show_shipment_details: false,
  show_item_images: false,
  show_item_options: false,
  show_shipment_prices: false,
  show_gift_link: false,
}

const BrandingDataContext = React.createContext({})
export const BrandingDataProvider = BrandingDataContext.Provider
export function useBrandingData(): Branding {
  const brandingData = useContext(BrandingDataContext) as ApiBranding

  let layout_key = LayoutKeys.Layout01
  Object.values(LayoutKeys).forEach(key => {
    if ((brandingData.layout_key as string) === (key as string)) {
      layout_key = key as LayoutKeys
    }
  })

  let theme_key = ThemeKeys.Light
  Object.values(ThemeKeys).forEach(value => {
    if (brandingData.theme_key === (value as string)) {
      theme_key = value
    }
  })

  const toggles = brandingData.toggles || DEFAULT_TOGGLES

  return {
    colors: toggles.show_custom_colors ? brandingData.colors : undefined,
    email: brandingData.email,
    layout_key: layout_key,
    logo_type: brandingData.logo_type || 'text',
    logo_url: brandingData.logo_url,
    menu_links: (brandingData.menu_links || []).map(link => link),
    phone: brandingData.phone,
    powered_by: brandingData.powered_by
      ? {
          name: brandingData.powered_by.name,
          logo_url: brandingData.powered_by.logo_url,
          url: brandingData.powered_by.url,
        }
      : undefined,
    return_policy: brandingData.return_policy,
    social_media: (brandingData.social_media || []).map(link => link),
    store_name: brandingData.store_name,
    store_url: brandingData.store_url,
    theme_key: theme_key,
    toggles: toggles,
    promo_images: brandingData.promo_images,
  }
}
